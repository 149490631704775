import auth0 from "auth0-js";
import moment from "moment";
import {
  AUTH0_CLIENTID,
  AUTH0_AUDIENCE,
  AUTH0_DOMAIN,
  MAIN_DOMAIN_URL,
} from "../constants/environments";

const loginConfig = {
  connection: "Username-Password-Authentication",
  scope: "openid email user_metadata",
};
export const getTokenInfo = () =>
  new Promise((resolve, reject) => {
    auth.parseHash((error, result) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(result);
    });
  });
export const AUTH_CONFIG = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENTID,
  callbackUrl: `${MAIN_DOMAIN_URL}/auth`,
};

export const auth = new auth0.WebAuth({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  redirectUri: AUTH_CONFIG.callbackUrl,
  audience: AUTH0_AUDIENCE,
  responseType: "token id_token",
  scope: "openid email",
});

export const login = (email, password) => {
  const loginParams = {
    ...loginConfig,
    email,
    password,
  };
  return new Promise((resolve, reject) => {
    auth.popup.loginWithCredentials(loginParams, (error, data) => {
      if (error) {
        reject(error);
        return;
      }
      resolve({
        expiresIn: data.expiresIn,
        accessToken: data.accessToken,
      });
    });
  });
};
export const getExpirationTime = (expiresIn) => moment().unix() + expiresIn;

export const logout = () => {
  sessionStorage.removeItem("access_token");
};
