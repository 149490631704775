import axios from "axios";
import axiosConfig from "./axios-client";

export default class APIAdapter {
  constructor() {
    this.accessToken = sessionStorage.getItem("access_token");
    axiosConfig();
  }

  async uploadIdFront(data) {
    try {
      const response = await axios.post(
        "/v1/profile/civil-id-renewal/front",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
     
    } catch (error) {
      
      console.error("Error:", error);
    }
  }
  async uploadIdBack(data) {
    try {
      const response = await axios.post(
        "/v1/profile/civil-id-renewal/back",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
    } catch (error) {
     
      console.error("Error:", error);
    }
  }

  async checkFileNotEmpty() {
    try {
      const response = await axios.get(
        "/v1/profile/civil-id-renewal/civil-renewal"
      );
      const {
        civil_id_back_url: civilIdBackUrl,
        civil_id_front_url: civilIdFrontUrl,
        civil_id_upload_date: civilIdUploadDate,
      } = response.data;
      return civilIdBackUrl && civilIdFrontUrl && civilIdUploadDate
        ? true
        : false;
    } catch (error) {
      return false;
    }
  }
}
