import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

const LogoutButton = ({ title }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication information and perform logout
    sessionStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("access_token_expires_in");
    sessionStorage.removeItem("access_token");
    navigate("/login");
  };

  return (
    <div className="logout-div">
      <button className="logout-button" onClick={handleLogout}>
        {title}
      </button>
    </div>
  );
};

export default LogoutButton;
