import React from "react";
import propTypes from "prop-types";

import "./style.scss";

const Dimmer = (props) => {
  if (!props.isVisible) {
    return null;
  }
  return <div data-testid="dimmer" id="std_dimmer" />;
};

Dimmer.propTypes = {
  isVisible: propTypes.bool,
};

Dimmer.defaultProps = {
  isVisible: true,
};

export default Dimmer;
