const Enums = {
  button: {
    variants: {
      primary: "primary",
      secondary: "secondary",
      upload: "upload",
    },
    sizes: {
      small: "small",
      large: "large",
      auto: "auto",
    },
    types: {
      button: "button",
      submit: "submit",
      reset: "reset",
    },
  },
  uploadId: {
    acceptedTypes: ["pdf", "png", "jpg", "jpeg", "jfif", "heif", "heic"],
    maxAllowedSize: 5 * 1024 * 1024,
    types: {
      pdf: "pdf",
      png: "png",
      jpg: "jpg",
      jpeg: "jpeg",
      jfif: "jfif",
      heif: "heif",
      heic: "heic",
    },
  },
};
export default Enums;
