import React, { useEffect, useState } from "react";
import UploadCard from "../../components/upload-card";
import Button from "../../components/button";
import Enums from "../../constants/enums";
import FileUtils from "../../utils/file";
import UploadWarningModal from "../../components/upload-warning-modal";
import localization from "./localization";
import APIAdapter from "../../utils/api-adapter";
import Popup from "../../components/popup-message";
import LogoutButton from "../../components/logout";
import Dimmer from "../../components/Dimmer";
import "./style.scss";

function Upload() {
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [fileSizeModalOpen, setFileSizeModalOpen] = useState(false);
  const [fileTypeModalOpen, setFileTypeModalOpen] = useState(false);
  const [isFrontUploaded, setIsFrontUploaded] = useState(false);
  const [isFrontAttached, setIsFrontAttached] = useState(false);
  const [isBackUploaded, setIsBackUploaded] = useState(false);
  const [isBackAttached, setIsBackAttached] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupUploaded, setShowPopupUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const openUploadedPopup = () => {
    setShowPopupUploaded(true);
  };

  const closeUploadedPopup = () => {
    setShowPopupUploaded(false);
    setUploadButtonDisabled(true);
  };

  const handleFrontFileSelected = (file) => {
    if (!file) {
      return;
    }

    const _file = file;
    const fileName = _file.name;
    const fileUtils = new FileUtils();
    const ext = fileUtils.getFileExtension(fileName);

    if (ext === Enums.uploadId.types.pdf) {
      if (checkFileType("front", ext)) {
        if (pdfSizeCheck("front", file, ext)) {
          if (pdfFileSave("front", file, ext)) {
            console.log("File done");
          }
        }
      }
    } else {
      if (checkFileType("front", ext)) {
        if (liveImageCompress(file)) {
          if (file.size <= Enums.uploadId.maxAllowedSize) {
            saveImage("front", file);
          } else {
            saveCompressedImage("front", file);
          }
        }
      }
    }
  };

  const handleBackFileSelected = (file) => {
    if (!file) {
      return;
    }

    const _file = file;
    const fileName = _file.name;
    const fileUtils = new FileUtils();
    const ext = fileUtils.getFileExtension(fileName);

    if (ext === Enums.uploadId.types.pdf) {
      if (checkFileType("back", ext)) {
        if (pdfSizeCheck("back", file, ext)) {
          if (pdfFileSave("back", file, ext)) {
            console.log("File done");
          }
        }
      }
    } else {
      if (checkFileType("back", ext)) {
        if (liveImageCompress(file)) {
          if (file.size <= Enums.uploadId.maxAllowedSize) {
            saveImage("back", file);
          } else {
            saveCompressedImage("back", file);
          }
        }
      }
    }
  };

  const saveCompressedImage = async (fileType, file) => {
    try {
      const _file = file;
      const fileUtils = new FileUtils();

      const compressedFile = await fileUtils.compressFile(_file);
      if (compressedFile.size > Enums.uploadId.maxAllowedSize) {
        if (fileType === "front") {
          setIsFrontAttached(false);
          setFrontFile(null);
          setIsFrontUploaded(false);
        } else if (fileType === "back") {
          setIsBackAttached(false);
          setBackFile(null);
          setIsBackUploaded(false);
        }

        setFileSizeModalOpen(true);
        setIsContinueDisabled(true);
      } else {
        if (fileType === "front") {
          saveFrontFile(compressedFile);
        } else if (fileType === "back") {
          saveBackFile(compressedFile);
        }
      }
    } catch (err) {
      throw err;
    }
  };
  const closeFileSizeModal = () => {
    setFileSizeModalOpen(false);
  };
  const closeFileTypeModal = () => {
    setFileTypeModalOpen(false);
  };
  const saveImage = (fileType, file) => {
    const _file = file;

    if (fileType === "front") {
      saveFrontFile(_file);
    } else if (fileType === "back") {
      saveBackFile(_file);
    }
  };

  const checkFileType = (fileType, ext) => {
    try {
      if (!ext || !Enums.uploadId.acceptedTypes.includes(ext)) {
        if (fileType === "front") {
          setIsFrontAttached(false);
          setFrontFile(null);
          setIsFrontUploaded(false);
        } else if (fileType === "back") {
          setIsBackAttached(false);
          setBackFile(null);
          setIsBackUploaded(false);
        }

        setFileTypeModalOpen(true);
        setIsContinueDisabled(true);
        return false;
      }
      return true;
    } catch (err) {
      throw err;
    }
  };
  const pdfSizeCheck = (fileType, file, ext) => {
    const _file = file;

    try {
      if (
        ext === Enums.uploadId.types.pdf &&
        _file.size > Enums.uploadId.maxAllowedSize
      ) {
        if (fileType === "front") {
          setIsFrontAttached(false);
          setFrontFile(null);
          setIsFrontUploaded(false);
        } else if (fileType === "back") {
          setIsBackAttached(false);
          setBackFile(null);
          setIsBackUploaded(false);
        }

        setFileSizeModalOpen(true);
        setIsContinueDisabled(true);
        return false;
      }
      return true;
    } catch (err) {
      throw err;
    }
  };

  const pdfFileSave = (fileType, file, ext) => {
    const _file = file;

    try {
      if (
        ext === Enums.uploadId.types.pdf &&
        _file.size <= Enums.uploadId.maxAllowedSize
      ) {
        if (fileType === "front") {
          saveFrontFile(_file);
        } else if (fileType === "back") {
          saveBackFile(_file);
        }

        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  };

  const saveFrontFile = (file) => {
    setFrontFile(file);
    setIsFrontAttached(true);
    setIsFrontUploaded(true);
    if (backFile && backFile.name) {
      setIsContinueDisabled(false);
    } else {
      setIsContinueDisabled(true);
    }
  };
  const saveBackFile = (file) => {
    setBackFile(file);
    setIsBackAttached(true);
    setIsBackUploaded(true);

    if (frontFile && frontFile.name) {
      setIsContinueDisabled(false);
    } else {
      setIsContinueDisabled(true);
    }
  };

  const liveImageCompress = async (file) => {
    let _file = file;
    const fileName = _file.name;
    const fileUtils = new FileUtils();
    const ext = fileUtils.getFileExtension(fileName);
    const livePhotosTypes = [
      Enums.uploadId.types.heif,
      Enums.uploadId.types.heic,
    ];

    try {
      if (livePhotosTypes.includes(ext)) {
        _file = await fileUtils.convertHEICTOJPEG(file);
        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  };

  // useEffect(() => {
  //   if (uploadButtonDisabled && !showPopup) {
  //     openPopup();
  //   }
  // }, [uploadButtonDisabled]);

  const checkFile = async () => {
    const api = new APIAdapter();
    const fileNotEmpty = await api.checkFileNotEmpty();

    if (fileNotEmpty) {
      setUploadButtonDisabled(true);
      openPopup();
    }
  };
  useEffect(() => {
    checkFile();
  }, []);

  useEffect(() => {
    return () => {
      setIsContinueDisabled(true);
    };
  }, []);

  useEffect(() => {
    if (frontFile !== null && backFile !== null) {
      setIsContinueDisabled(false);
    } else {
      setIsContinueDisabled(true);
    }
  }, [frontFile, backFile]);

  const uploadFront = async () => {
    try {
      const data = new FormData();
      data.append("uploaded_file", frontFile);
      const api = new APIAdapter();
       await api.uploadIdFront(data);
      return true
    } catch (exception) {
      return false;
    }
  };
  const uploadBack = async () => {
    try {
      const data = new FormData();
      data.append("uploaded_file", backFile);
      const api = new APIAdapter();
      await api.uploadIdBack(data);
      return true
    } catch (exception) {
      return false;

    }
  };

  const onContinue = async () => {
    setIsLoading(true);
    let response1 = false;
    let response2 = false;
    try {
      if (isFrontUploaded) {
        response1 = await uploadFront();
      }
      if (isBackUploaded) {
        response2 = await uploadBack();
      }
      if (response1 && response2) {
        setIsLoading(false);
        openUploadedPopup();
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {isLoading && <Dimmer isVisible={isLoading} />}
      <LogoutButton title="Logout" />

      <div id="page-upload">
        <UploadWarningModal
          open={fileSizeModalOpen}
          onClose={closeFileSizeModal}
          translation={localization.fileSizeModal}
        />
        <UploadWarningModal
          open={fileTypeModalOpen}
          onClose={closeFileTypeModal}
          translation={localization.fileTypeModal}
        />
        <div className="content">
          <p className="title">{localization.uploadMessage}</p>
          <UploadCard
            side="front"
            onFileSelected={handleFrontFileSelected}
            file={frontFile}
            isUploaded={isFrontUploaded}
            isAttached={isFrontAttached}
            fileName={frontFile ? frontFile.name : ""}
            extraClass="upload-front"
            uploadButtonDisabled={uploadButtonDisabled}
          />
          <UploadCard
            side="back"
            onFileSelected={handleBackFileSelected}
            file={backFile}
            isUploaded={isBackUploaded}
            isAttached={isBackAttached}
            fileName={backFile ? backFile.name : ""}
            uploadButtonDisabled={uploadButtonDisabled}
          />
        </div>
        <div className="footer">
          <div className="container">
            {!uploadButtonDisabled ? (
              <Button
                variant="primary"
                isDisabled={isContinueDisabled}
                extraClass="continue-btn"
                onClick={onContinue}
              >
                {localization.continueLabel}
              </Button>
            ) : null}
          </div>
        </div>
        <div>
          {showPopup && (
            <Popup
              message={localization.alreadyUploadedFilesMessage}
              onClose={closePopup}
            />
          )}
          {showPopupUploaded && (
            <Popup
              message={localization.UploadedFilesMessage}
              onClose={closeUploadedPopup}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Upload;
