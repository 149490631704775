import React from "react";
import PropTypes from "prop-types";
import Icons from "../../assets/icons";
import classNames from "classnames";

import "./style.scss";

const Modal = ({ children, onClose, open, hasCloseButton }) => (
  <div className="std-modal">
    <div
      className={classNames({
        hidden: !open,
      })}
    >
      <div className="backdrop" />
      <div className="modal-content center">
        <img
          src={Icons.Close}
          onClick={onClose}
          className={classNames({ close: true, hidden: !hasCloseButton })}
          alt="close-modal"
          role="presentation"
        />
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  hasCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  hasCloseButton: true,
};

export default Modal;
