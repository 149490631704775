import Compressor from "compressorjs";
import heic2any from "heic2any";

export default class FileUtils {
  getFileExtension = (fileName) => {
    const ext = fileName.match(/\.([^.]+)$/);
    if (!ext) return null;
    return ext[1];
  };

  truncateFileName = (fileName, limit) => {
    if (!fileName) return "";
    if (fileName.length < limit) return fileName;
    return `${fileName.slice(0, limit)}...`;
  };

  compressFile(file) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: 0.3,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  async convertHEICTOJPEG(blob) {
    const convertedBlob = await heic2any({
      blob,
      toType: "image/jpeg",
      quality: 0.5,
    });
    return new File([convertedBlob], blob.name, { type: convertedBlob.type });
  }

  blobToBase64(blob) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}
