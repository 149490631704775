import React from "react";
import PropTypes from "prop-types";
import Enums from "../../constants/enums";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import UploadButton from "./UploadButton";

const Button = (props) => {
  switch (props.variant) {
    case Enums.button.variants.primary:
      return <PrimaryButton {...props} />;
    case Enums.button.variants.secondary:
      return <SecondaryButton {...props} />;
    case Enums.button.variants.upload:
      return <UploadButton {...props} />;
    default:
      return <PrimaryButton {...props} />;
  }
};

Button.propTypes = {
  variant: PropTypes.oneOf([
    Enums.button.variants.primary,
    Enums.button.variants.secondary,
    Enums.button.variants.upload,
  ]),
};

Button.defaultProps = {
  variant: Enums.button.variants.primary,
};

export default Button;
