import React from "react";
import PropTypes from "prop-types";
import Button from "../button";
import Icons from "../../assets/icons";
import classNames from "classnames";
import { localization } from "./localization";
import "./style.scss";

const UploadCard = (props) => {
  const {
    side,
    extraClass,
    onFileSelected,
    file,
    isUploaded,
    fileName,
    isAttached,
    uploadButtonDisabled,
  } = props;
  const icon = side === "front" ? Icons.UploadFront : Icons.UploadBack;

  return (
    <div
      className={classNames({ "std-upload-card": true, [extraClass]: true })}
    >
      <div className="image-container">
        <img src={icon} alt="upload icon" />
      </div>
      <p className="step-title">
        {side === "front" ? "Step 1: Upload Front" : "Step 2: Upload Back"}
      </p>
      <p className="step-subtitle">{localization.uploadFileMessage}</p>
      <div className="btn-container">
        <Button
          size="auto"
          variant="upload"
          file={file}
          onFileSelected={onFileSelected}
          side={side}
          isDisabled={uploadButtonDisabled}
        >
          Upload
        </Button>
        <div
          className={classNames({
            "upload-status": true,
            hidden: !isAttached && !isUploaded ? true : false,
          })}
        >
          <div className="filename">
            {isUploaded && !fileName ? "File uploaded" : "File Attached"}
          </div>
          <img
            className="checkicon"
            src={Icons.UploadCheckCircle}
            alt="upload check"
          />
          <div className="filename1">{fileName}</div>
        </div>
      </div>
      <p className="upload-notes">{localization.maximumFileSize}</p>
    </div>
  );
};

UploadCard.propTypes = {
  side: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
  onFileSelected: PropTypes.func,
  file: PropTypes.object,
  isUploaded: PropTypes.bool,
  fileName: PropTypes.string,
  isAttached: PropTypes.bool,
};

export default UploadCard;
