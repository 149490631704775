import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icons from "../../../assets/icons";
import "./style.scss";

const UploadButton = (props) => {
  const { children, extraClass, isDisabled, onFileSelected, accept, file } =
    props;
  const inputRef = useRef(null);
  const classes = classNames({
    [extraClass]: true,
    "std-upload-button": true,
  });

  const onClick = () => {
    inputRef.current.click();
  };
  const onInputChange = (event) => {
    onFileSelected(event.target.files[0]);
    inputRef.current.value = "";
  };
  useEffect(() => {
    if (file === {}) {
      inputRef.current.value = "";
    }
  }, [file]);
  return (
    <>
      <input
        type="file"
        data-testid="file-input"
        accept={accept}
        ref={inputRef}
        onChange={onInputChange}
        style={{ display: "none" }}
      />
      <button
        type="button"
        className={classes}
        disabled={isDisabled}
        onClick={onClick}
      >
        <img src={Icons.UploadButtonIcon} alt="upload button" />
        {children}
      </button>
    </>
  );
};

UploadButton.propTypes = {
  extraClass: PropTypes.string,
  isDisabled: PropTypes.bool,
  onFileSelected: PropTypes.func,
  children: PropTypes.node.isRequired,
  accept: PropTypes.string,
  side: PropTypes.string,
  file: PropTypes.object,
};

UploadButton.defaultProps = {
  isDisabled: false,
  extraClass: "",
  accept: "",
};

export default UploadButton;
