import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Authenticator = () => {
  const navigate = useNavigate();
  const [isAuthenticated] = useState(
    !!sessionStorage.getItem("isAuthenticated")
  );
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    const testIfTokenValid = () => {
      try {
        const expirationTime = parseInt(
          sessionStorage.getItem("access_token_expires_in")
        );
        const currentTime = moment().unix();
        return expirationTime && expirationTime > currentTime;
      } catch (error) {
        return false;
      }
    };

    const validateToken = () => {
      if (isAuthenticated) {
        const isValid = testIfTokenValid();
        if (!isValid) {
          setIsTokenExpired(true);
          navigate("/login");
        } else {
          navigate("/upload-civil-id");
        }
      } else {
        navigate("/login");
      }
    };

    validateToken();
  }, []);

  return isTokenExpired ? (
    <div>Token has expired. Please log in again.</div>
  ) : (
    <div>Validating token...</div>
  );
};

export default Authenticator;
