import axios from "axios";
import { API_URL, PLATFORM, VERSION } from "../constants/environments";
import { clearSessionToken, redirectToLogin } from "./index";
let isReqInterceptorInitilizaed = false;
let isResInterceptorInitilizaed = false;

const axiosConfig = () => {
  if (!navigator.cookieEnabled) {
    return;
  }
  const accessToken = sessionStorage.getItem("access_token");
  const impersonationToken = sessionStorage.getItem("impersonation_token");
  axios.defaults.baseURL = API_URL;
  const impersonationHeader = impersonationToken
    ? `Bearer ${accessToken} ${impersonationToken}`
    : `Bearer ${accessToken}`;
  const headerConfig = {
    "X-Platform": PLATFORM,
    "X-Version": VERSION,
    "X-Locale": "en_US",
    Authorization: accessToken ? impersonationHeader : "",
  };
  axios.defaults.headers.common = { ...headerConfig };
  // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  if (!isReqInterceptorInitilizaed) {
    isReqInterceptorInitilizaed = true;
    axios.interceptors.request.use(
      (request) => request,
      (error) => error
    );
  }

  if (!isResInterceptorInitilizaed) {
    isResInterceptorInitilizaed = true;
    axios.interceptors.response.use(
      (response) => response,
      (error) =>
        new Promise((resolve, reject) => {
          let errorStatus = 0;
          if (error.response) {
            errorStatus = error.response.status;
            if ([401].includes(errorStatus)) {
              clearSessionToken();
              redirectToLogin(100);
              return;
            }
          }
          reject({
            code: errorStatus,
            body: error.response.data.description,
          });
        })
    );
  }
};

export default axiosConfig;
