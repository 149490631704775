const localization = {
  fileSizeModal: {
    title: "File size too large",
    subtitle: "The uploaded file is too large. The max file size is 5MB.",
    buttonText: "Upload new file",
  },
  fileTypeModal: {
    title: "File type is not supported",
    subtitle:
      "The file extension is not allowed. You can only upload (pdf, png, jpg, jfif, heif and jpeg) files.",
    buttonText: "Upload new file",
  },
  uploadMessage: "Please upload the front and back of your Civil ID",
  alreadyUploadedFilesMessage:
    "Your front and back civil id files are already uploaded. You cannot re-upload.",
  UploadedFilesMessage:
    "Your front and back civil id files are uploaded successfully.",
  continueLabel: "Continue",
};

export default localization;
