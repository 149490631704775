import React from "react";
import Images from "../../assets/images";

import "./style.scss";

const header = () => (
  <div id="std-header">
    <img src={Images.HeaderLogo} className="logo" alt="logo" />
  </div>
);

export default header;
