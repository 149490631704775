
import Email from "./icon-mail.svg";
import UploadFront from "./upload-front.svg";
import Password from "./icon-password.svg";
import UploadBack from "./upload-back.svg";
import UploadCheckCircle from "./upload-check-circle.svg";
import AlertTriangle from "./alert-triangle.svg";
import UploadButtonIcon from "./upload-button.svg";
import Close from "./close.svg";

const Icons = {
  Email,
  Password,
  UploadFront,
  UploadBack,
  UploadCheckCircle,
  AlertTriangle,
  UploadButtonIcon,
  Close,
};

export default Icons;
