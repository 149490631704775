import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import Enums from "../../../constants/enums";

import "./style.scss";

const PrimaryButton = (props) => {
  const { size, extraClass, isDisabled, onClick } = props;
  const classes = classNames({
    "std-primary-button": true,
    [extraClass]: true,
    [size]: true,
  });
  return (
    <button
      type="button"
      data-testid="primary-button"
      className={classes}
      disabled={isDisabled}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

PrimaryButton.propTypes = {
  size: propTypes.oneOf([
    Enums.button.sizes.small,
    Enums.button.sizes.large,
    Enums.button.sizes.auto,
  ]),
  extraClass: propTypes.string,
  isDisabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node.isRequired,
};

PrimaryButton.defaultProps = {
  size: Enums.button.sizes.small,
  isDisabled: false,
  extraClass: "",
};

export default PrimaryButton;
