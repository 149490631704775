function getEnvironment() {
  const { REACT_APP_ENVIRONMENT } = process.env;
  const config = {
    PLATFORM: "web",
    VERSION: "1.0",
  };
  switch (REACT_APP_ENVIRONMENT) {
    case "production":
      return {
        ...config,
        API_URL: "https://api.nbkcapitalsmartwealth.com",
        MAIN_DOMAIN_URL: "https://renewal.nbkcapitalsmartwealth.com",
        AUTH0_DOMAIN: "auth.nbkcapitalsmartwealth.com",
        AUTH0_CLIENTID: "9KQdxQE7a8yL24J3mKRdx9P4NTzxBvg5",
        AUTH0_AUDIENCE: "https://nbkcapitalsmartwealth.eu.auth0.com/api/v2/",
      };
    case "uat":
      return {
        ...config,
        API_URL: "https://api.nbkcapital-smartwealth.com",
        MAIN_DOMAIN_URL: "https://d1znoavoiyhigc.cloudfront.net",
        AUTH0_DOMAIN: "auth.staging-nbksmartwealth.com",
        AUTH0_CLIENTID: "l72d0nidcmi3OP2JW6YCp865hRkd54hP",
        AUTH0_AUDIENCE: "https://staging-nbksmartwealth.eu.auth0.com/api/v2/",
      };
    case "internal-uat":
      return {
        ...config,
        API_URL: "https://sw-internal-uat.nbkcapital-smartwealth.com",
        MAIN_DOMAIN_URL: "https://d1znoavoiyhigc.cloudfront.net",
        AUTH0_DOMAIN: "auth.staging-nbksmartwealth.com",
        AUTH0_CLIENTID: "l72d0nidcmi3OP2JW6YCp865hRkd54hP",
        AUTH0_AUDIENCE: "https://staging-nbksmartwealth.eu.auth0.com/api/v2/",
      };
    case "staging":
      return {
        ...config,
        API_URL: "https://api-sta.staging-nbksmartwealth.com",
        MAIN_DOMAIN_URL: "https://d1znoavoiyhigc.cloudfront.net",
        AUTH0_DOMAIN: "auth.staging-nbksmartwealth.com",
        AUTH0_CLIENTID: "l72d0nidcmi3OP2JW6YCp865hRkd54hP",
        AUTH0_AUDIENCE: "https://staging-nbksmartwealth.eu.auth0.com/api/v2/",
      };
    default:
      return {
        ...config,

        API_URL: "https://tesla-internal-uat.nbkcapital-smartwealth.com",
        MAIN_DOMAIN_URL: "http://neo-frontend",
        AUTH0_DOMAIN: "auth.staging-nbksmartwealth.com",
        AUTH0_CLIENTID: "l72d0nidcmi3OP2JW6YCp865hRkd54hP",
        AUTH0_AUDIENCE: "https://staging-nbksmartwealth.eu.auth0.com/api/v2/",

        // AUTH0_AUDIENCE: "https://api.nbkcapital-smartwealth.com/api",
      };
  }
}

export const {
  PLATFORM,
  VERSION,
  API_URL,
  MAIN_DOMAIN_URL,
  AUTH0_DOMAIN,
  AUTH0_CLIENTID,
  AUTH0_AUDIENCE,
} = getEnvironment();
