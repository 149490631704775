import React, { useState } from "react";
import Icons from "../../assets/icons";
import Input from "../../components/Input/unauthorized-input";
import Button from "../../components/login-button";
import { validateEmail, validatePassword } from "../../utils/validator";
import { Localization } from "./localization";
import HeaderComponent from "../../components/header";
import { login, getExpirationTime } from "../../utils/auth0";
import { setAccessToken, clearSessionToken } from "../../utils";
import { useNavigate } from "react-router-dom";
import Dimmer from "../../components/Dimmer";
import "./style.scss";

function Index() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onInputChnage = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(email) || !validatePassword(password)) {
      setErrorMessage(Localization.errorMessage);
      return;
    }

    await loginByAuth0(email, password);
  };

  const loginByAuth0 = async (email, password) => {
    try {
      setIsLoading(true);
      const { expiresIn, accessToken } = await login(email, password);
      const expirationTime = getExpirationTime(expiresIn);
      setAccessToken(accessToken, expirationTime);
      sessionStorage.setItem("isAuthenticated", true);

      try {
        navigate("/auth");
        setIsLoading(false);
      } catch (exception) {
        clearSessionToken();
        throw new Error({ message: "Unable to login from this IP Address" });
      }
    } catch (exception) {
      switch (exception.code) {
        case "access_denied":
          setErrorMessage("Wrong username or password");
          break;
        case "too_many_attempts":
          setErrorMessage("Too mnay attempts");

          break;
        case "unauthorized":
          setErrorMessage("Unauthorized");

          break;
        default:
          setErrorMessage("Network error");

          break;
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeaderComponent />
      {isLoading && <Dimmer isVisible={isLoading} />}
      <div data-testid="login" id="login-page">
        <div className="login-container">
          <div className="login-wrapper">
            <h2>{Localization.loginMessage} </h2>
            <form onSubmit={onSubmit} noValidate>
              <Input
                name="email"
                type="email"
                value={email}
                icon={Icons.Email}
                id="email-input"
                extraClass="login-field"
                placeholder={Localization.emailPlaceHolder}
                onChange={onInputChnage}
              />
              <Input
                name="password"
                type="password"
                icon={Icons.Password}
                value={password}
                id="password-input"
                extraClass="login-field"
                placeholder={Localization.passwordPlaceHolder}
                onChange={onInputChnage}
              />
              <Button
                type="submit"
                extraClass="submit"
                size="medium"
                isRTL={false}
                isDisabled={isLoading}
              >
                {Localization.loginLabel}
              </Button>
              <div className="error">{errorMessage}</div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
