import { MAIN_DOMAIN_URL } from "../constants/environments";

export const setAccessToken = (accessToken, expirationTime) => {
  sessionStorage.setItem("access_token", accessToken);
  sessionStorage.setItem("access_token_expires_in", expirationTime);
};

export const clearSessionToken = () => sessionStorage.clear();

export const redirectToLogin = (time = 0) => {
  setTimeout(() => {
    window.location.href = `${MAIN_DOMAIN_URL}/login`;
  }, time);
};
export const redirectToHome = (time = 0) => {
  setTimeout(() => {
    window.location.href = `${MAIN_DOMAIN_URL}`;
  }, time);
};
export const getAccessTokenExpiration = () => {
  const accessTokenExpiration = sessionStorage.getItem(
    "access_token_expires_in"
  );
  if (!accessTokenExpiration) {
    return null;
  }
  return parseInt(accessTokenExpiration, 10);
};
