import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import Icons from "../../assets/icons";

import "./style.scss";

const Index = (props) => {
  const {
    size,
    extraClass,
    type,
    withArrow,
    isDisabled,
    isGradient,
    isRTL,
    onClick,
    isPrimary,
    isSecondary,
  } = props;
  const classes = classNames({
    [size]: true,
    [extraClass]: true,
    "std-button": true,
    gradient: isGradient,
    rtl: isRTL,
    primary: isPrimary,
    secondary: isSecondary,
  });
  return (
    /* eslint-disable react/button-has-type */ // DISABLED
    <button
      type={type}
      className={classes}
      disabled={isDisabled}
      onClick={onClick}
    >
      {isRTL && withArrow && (
        <img
          src={isDisabled ? Icons.RightArrowGray : Icons.RightArrow}
          alt="arrow"
          className={classes}
        />
      )}
      {props.children}
      {!isRTL && withArrow && (
        <img
          src={isDisabled ? Icons.RightArrowGray : Icons.RightArrow}
          alt="grey-arrow"
          className={classes}
        />
      )}
    </button>
  );
};

Index.propTypes = {
  size: propTypes.oneOf(["small", "large", "medium"]),
  extraClass: propTypes.string,
  isDisabled: propTypes.bool,
  isGradient: propTypes.bool,
  withArrow: propTypes.bool,
  isRTL: propTypes.bool,
  type: propTypes.oneOf(["button", "submit"]),
  onClick: propTypes.func,
  children: propTypes.node.isRequired,
  isPrimary: propTypes.bool,
  isSecondary: propTypes.bool,
};

Index.defaultProps = {
  size: "small",
  type: "button",
  isDisabled: false,
  withArrow: false,
  isGradient: false,
  isRTL: false,
  extraClass: "",
  isPrimary: false,
  isSecondary: false,
};

export default Index;
