import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import Upload from "../pages/upload-civil-id";
import Authenticator from "../components/Authenticator";

const RmTerminalRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/upload-civil-id" element={<Upload />} />
        <Route path="/auth" element={<Authenticator />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RmTerminalRouter;
