import React from "react";
import PropTypes from "prop-types";
import Modal from "../modal";
import Button from "../button";
import Icons from "../../assets/icons";
import "./style.scss";

const UploadWarningModal = (props) => {
  const { open, onClose, translation } = props;
  return (
    <Modal open={open} onClose={onClose}>
      <div className="std-upload-warning-modal">
        <img src={Icons.AlertTriangle} alt="alert-triangle" />
        <p className="modal-title">{translation.title}</p>
        <p className="modal-subtitle">{translation.subtitle}</p>
        <div>
          <Button
            variant="primary"
            extraClass="accept-btn"
            size="auto"
            onClick={onClose}
          >
            {translation.buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

UploadWarningModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  translation: PropTypes.object,
};

export default UploadWarningModal;
