import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import "./style.scss";

class Index extends React.Component {
  renderErrorMessage() {
    const { errorMessage, icon } = this.props;
    const classes = classNames({
      "error-message": true,
      "error-message-icon": icon,
    });
    if (!errorMessage) {
      return null;
    }
    if (typeof errorMessage === "string") {
      return (
        <div className={classes}>
          <FormattedMessage id={errorMessage} />
        </div>
      );
    }
    return <div className={classes}>{errorMessage}</div>;
  }

  render() {
    const {
      extraClass,
      type,
      name,
      errorMessage,
      placeholder,
      onChange,
      value,
      onBlur,
      autoComplete,
      icon,
      isDisabled,
      id,
      onFocus,
    } = this.props;
    const classes = classNames({
      [extraClass]: true,
      "unauthorized-input": true,
    });
    return (
      <div data-testid="unauthorized-input" className={classes}>
        <div
          className={classNames({
            error: errorMessage.length > 0,
            "input-container": true,
          })}
        >
          {icon ? (
            <div className="icon">
              <img src={icon} alt="input-icon" />
            </div>
          ) : (
            ""
          )}
          <div
            className={classNames({
              "input-wrapper": !icon,
              "input-wrapper-icon": icon,
            })}
          >
            <input
              id={id}
              disabled={isDisabled}
              autoComplete={autoComplete}
              name={name}
              value={value}
              type={type}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
            />
            <label htmlFor={id}> {placeholder}</label>
          </div>
        </div>
        {this.renderErrorMessage()}
      </div>
    );
  }
}

Index.propTypes = {
  locale: propTypes.string,
  name: propTypes.string,
  label: propTypes.string,
  extraClass: propTypes.string,
  errorMessage: propTypes.string,
  placeholder: propTypes.string,
  type: propTypes.oneOf(["text", "email", "password", "calendar"]),
  onChange: propTypes.func.isRequired,
  onShowPassword: propTypes.func,
  value: propTypes.string,
  isPassword: propTypes.bool,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  tooltip: propTypes.string,
  autoComplete: propTypes.string,
  isDisabled: propTypes.bool,
  icon: propTypes.node,
};

Index.defaultProps = {
  name: "text",
  errorMessage: "",
  type: "text",
  extraClass: "",
  placeholder: "",
  isPassword: false,
  tooltip: "",
  autoComplete: "off",
  onBlur: () => {},
  onFocus: () => {},
  isDisabled: false,
  // icon: <></>
};

export default Index;
